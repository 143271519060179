export const colors = {
    light: {
        background: "rgb( 240 240 240)",
        surface: "#79797990",
        primary: "#fc5a30",
        secondary: "#fba438",
        onBackground: "#3e3e3e",
        onSurface: "#333",
        onPrimary: "#fff",
        onSecondary: "#fff",
        ui: {
            bright: "#e0d6eb",
            light: "#f5f3f7",
            whisper: "#fbfafc",
            descent: "#133144",
        },
        shades: {
            grey: "#afafaf",
        },
        shadows: {
            primary: "0 9px 25px 0 rgb(8 14 23 / 28%)",
        },
    },
    dark: {
        background: "#121212",
        surface: "#333333e8",
        primary: "#fc5a30",
        secondary: "#fba438",
        onBackground: "#fff",
        onSurface: "#fff",
        onPrimary: "#fff",
        onSecondary: "#fff",
        ui: {
            bright: "#e0d6eb",
            light: "#f5f3f7",
            whisper: "#fbfafc",
            descent: "rgb(252 90 48 / 60%)",
        },
        shades: {
            grey: "#afafaf",
        },
        shadows: {
            primary: "",
        },
    },
};

export const fonts = {
    sansSerif:
        '"Century Gothic", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
    serif: 'Georgia, "Times New Roman", Times, serif',
    monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
};

export const widths = {
    md: 720,
    lg: 960,
    xl: 1140,
};

export const dimensions = {
    fontSize: {
        small: 13,
        regular: 16,
        large: 18,
    },
    headingSizes: {
        h1: 2.441,
        h2: 1.953,
        h3: 1.563,
        h4: 1.25,
    },
    lineHeight: {
        regular: 1.45,
        heading: 1.2,
    },
    containerPadding: 1,
};

export const heights = {
    header: 60,
};
